import React from "react";
import NewsListS from "./NewsListS";

function SocialNews() {
  return (
    <div>
      <NewsListS />
    </div>
  );
}

export default SocialNews;
