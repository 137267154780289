import React from "react";
import NewsListG from "./NewsListG";

function GovernanceNews() {
  return (
    <div>
      <NewsListG />
    </div>
  );
}

export default GovernanceNews;
