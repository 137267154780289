import React from "react";
import NewsListE from "./NewsListE";
import * as S from "../../styles/News/News-style";

function EnvironmentNews() {
  return (
    <>
      <NewsListE />
    </>
  );
}

export default EnvironmentNews;
